const $ = jQuery;
let map = document.getElementById("locationsMap");

export default class GoogleMaps {
    constructor(options = null) {}

    init() {
        this.__importMap();
        this.__initMap();
    }

    __importMap() {
        ((g) => {
            var h,
                a,
                k,
                p = " The Google Maps JavaScript API",
                c = "google",
                l = "importLibrary",
                q = "__ib__",
                m = document,
                b = window;
            b = b[c] || (b[c] = {});
            var d = b.maps || (b.maps = {}),
                r = new Set(),
                e = new URLSearchParams(),
                u = () =>
                    h ||
                    (h = new Promise(async (f, n) => {
                        await (a = m.createElement("script"));
                        e.set("libraries", [...r] + "");
                        for (k in g)
                            e.set(
                                k.replace(
                                    /[A-Z]/g,
                                    (t) => "_" + t[0].toLowerCase()
                                ),
                                g[k]
                            );
                        e.set("callback", c + ".maps." + q);
                        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
                        d[q] = f;
                        a.onerror = () =>
                            (h = n(Error(p + " could not load.")));
                        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
                        m.head.append(a);
                    }));
            d[l]
                ? console.warn(p + " only loads once. Ignoring:", g)
                : (d[l] = (f, ...n) =>
                      r.add(f) && u().then(() => d[l](f, ...n)));
        })({
            key: site_data.google_api_key,
            v: "weekly",
            // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
            // Add other bootstrap parameters as needed, using camel case.
        });
    }

    async __initMap() {
        const { Map } = await google.maps.importLibrary("maps");
        const { Marker } = await google.maps.importLibrary("marker");

        if (map) {
            map = new Map(document.getElementById("locationsMap"), {
                center: { lat: 52.53486, lng: 1.16946 },
                zoom: 9,
                minZoom: 7,
                maxZoom: 17,
                mapTypeControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: [
                    {
                        featureType: "all",
                        elementType: "labels.text.stroke",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "landscape",
                        elementType: "all",
                        stylers: [
                            {
                                color: "#99B452",
                            },
                        ],
                    },

                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [
                            {
                                color: "#c9c9c9",
                            },
                        ],
                    },
                    {
                        featureType: "water",
                        elementType: "all",
                        stylers: [
                            {
                                color: "#ABDBDD",
                            },
                        ],
                    },
                ],
            });

            const markerIcon = {
                url: "/wp-content/themes/tessellate/assets/svgs/map_pin.svg",
                scaledSize: new google.maps.Size(40, 40),
                size: new google.maps.Size(40, 40),
            };

            const AttleboroughMarker = new Marker({
                map: map,
                position: { lat: 52.51692468860232, lng: 1.0152200068570203 },
                title: "Attleborough",
                icon: markerIcon,
            });

            const attleBoroughInfoBox = new google.maps.InfoWindow({
                content:
                    "<div><h3>" +
                    "Attleborough" +
                    "</h3><p class='d-flex flex-wrap w-75'>" +
                    "Attleborough Charity Shop, Central House, Exchange Street, Attleborough, Norfolk, NR17 2AB" +
                    "</p>" +
                    "</div>",
            });

            AttleboroughMarker.addListener("click", () => {
                attleBoroughInfoBox.open(map, AttleboroughMarker);
            });

            const CromerMarker = new Marker({
                map: map,
                position: {
                    lat: 52.93163,
                    lng: 1.29925,
                },
                title: "Cromer",
                icon: markerIcon,
            });

            const cromerInfoBox = new google.maps.InfoWindow({
                content:
                    "<div><h3>" +
                    "Cromer" +
                    "</h3><p class='d-flex flex-wrap w-75'>" +
                    "13 Garden Street, Cromer, Norfolk, NR27 9HN" +
                    "</p>" +
                    "</div>",
            });

            CromerMarker.addListener("click", () => {
                cromerInfoBox.open(map, CromerMarker);
            });

            const DerehamMarker = new Marker({
                map: map,
                position: {
                    lat: 52.68099,
                    lng: 0.94105,
                },
                title: "Dereham",
                icon: markerIcon,
            });

            const derehamInfoBox = new google.maps.InfoWindow({
                content:
                    "<div><h3>" +
                    "Dereham" +
                    "</h3><p class='d-flex flex-wrap w-75'>" +
                    "15 High Street, Dereham, Norfolk, NR19 1DZ" +
                    "</p>" +
                    "</div>",
            });

            DerehamMarker.addListener("click", () => {
                derehamInfoBox.open(map, DerehamMarker);
            });

            const NorwichMagdMarker = new Marker({
                map: map,
                position: {
                    lat: 52.63546,
                    lng: 1.2964,
                },
                title: "Norwich (Magdalen St)",
                icon: markerIcon,
            });

            const norwichMagdInfoBox = new google.maps.InfoWindow({
                content:
                    "<div><h3>" +
                    "Norwich (Magdalen St)" +
                    "</h3><p class='d-flex flex-wrap w-75'>" +
                    "58 Magdalen Street, Norwich, Norfolk, NR3 1JE" +
                    "</p>" +
                    "</div>",
            });

            NorwichMagdMarker.addListener("click", () => {
                norwichMagdInfoBox.open(map, NorwichMagdMarker);
            });

            const NorwichQueensRdMarker = new Marker({
                map: map,
                position: {
                    lat: 52.62074,
                    lng: 1.30154,
                },
                title: "Norwich (Queens Rd)",
                icon: markerIcon,
            });

            const norwichQueensRdInfoBox = new google.maps.InfoWindow({
                content:
                    "<div><h3>" +
                    "Norwich (Queens Road)" +
                    "</h3><p class='d-flex flex-wrap w-75'>" +
                    "10 Queens Road, Norwich, Norfolk, NR1 3PR" +
                    "</p>" +
                    "</div>",
            });

            NorwichQueensRdMarker.addListener("click", () => {
                norwichQueensRdInfoBox.open(map, NorwichQueensRdMarker);
            });

            const SheringhamMarker = new Marker({
                map: map,
                position: {
                    lat: 52.943844,
                    lng: 1.211766,
                },
                title: "Sheringham",
                icon: markerIcon,
            });

            const sheringhamInfoBox = new google.maps.InfoWindow({
                content:
                    "<div><h3>" +
                    "Sheringham" +
                    "</h3><p class='d-flex flex-wrap w-75'>" +
                    "37 High Street, Sheringham, Norfolk, NR26 8DS" +
                    "</p>" +
                    "</div>",
            });

            SheringhamMarker.addListener("click", () => {
                sheringhamInfoBox.open(map, SheringhamMarker);
            });

            const WymondhamMarker = new Marker({
                map: map,
                position: {
                    lat: 52.570827241880934,
                    lng: 1.1106140266046687,
                },
                title: "Wymondham",
                icon: markerIcon,
            });

            const wymondhamInfoBox = new google.maps.InfoWindow({
                content:
                    "<div><h3>" +
                    "Wymondham" +
                    "</h3><p class='d-flex flex-wrap w-75'>" +
                    "1 Church Street, Wymondham, Norfolk, NR18 0PH" +
                    "</p>" +
                    "</div>",
            });

            WymondhamMarker.addListener("click", () => {
                wymondhamInfoBox.open(map, WymondhamMarker);
            });

            const markers = [
                AttleboroughMarker,
                CromerMarker,
                DerehamMarker,
                NorwichMagdMarker,
                NorwichQueensRdMarker,
                SheringhamMarker,
                WymondhamMarker,
            ];

            let bounds = new google.maps.LatLngBounds();

            for (let i = 0; i < markers.length; i++) {
                bounds.extend(markers[i].getPosition());
            }

            map.fitBounds(bounds);

            // Function to perform reverse geocoding and get the address
            // function getAddressFromLatLng(latLng, callback) {
            //     const geocoder = new google.maps.Geocoder();
            //     geocoder.geocode({ location: latLng }, (results, status) => {
            //         if (status === "OK") {
            //             if (results[0]) {
            //                 callback(results[0].formatted_address);
            //             } else {
            //                 callback("Address not found");
            //             }
            //         } else {
            //             callback("Geocoder failed due to: " + status);
            //         }
            //     });
            // }
        }
    }
}
