const $ = jQuery;

export default class Base64 {
    constructor(options = null) {}

    init() {
        // BASE64 IMAGE
        const hiddenField = document.querySelector(
            'input[name="item_meta[647]"]'
        );

        // Create a new MutationObserver instance
        const observer = new MutationObserver((mutationsList, observer) => {
            // Loop through each mutation
            for (const mutation of mutationsList) {
                if (
                    mutation.type === "attributes" &&
                    mutation.attributeName === "value"
                ) {
                    const event = new Event("hiddenFieldChange");
                    document.dispatchEvent(event);
                }
            }
        });

        // Start observing the hidden field
        observer.observe(hiddenField, { attributes: true });

        // Example event listener for the custom event
        document.addEventListener("hiddenFieldChange", () => {
            // Perform actions you want when the hidden field value changes

            if (hiddenField.value == "") {
                $('[name="item_meta[650]"]').val("");
            } else {
                $(".frm_button_submit").prop("disabled", true);
                getImagePathByID(hiddenField.value);
            }
        });

        function getImagePathByID(imageID) {
            // AJAX request to WordPress REST API
            jQuery.ajax({
                url: site_data.wpjson + imageID, // adjust the URL based on your WordPress setup
                method: "GET",
                success: function (response) {
                    var imageUrl = response.source_url;

                    fetch(imageUrl)
                        .then((response) => response.blob())
                        .then((blob) => {
                            // Resize the image
                            const img = new Image();
                            img.src = URL.createObjectURL(blob);
                            img.onload = function () {
                                const maxWidth = 800;
                                let width = img.width;
                                let height = img.height;

                                // Calculate new dimensions while maintaining aspect ratio
                                if (width > maxWidth) {
                                    const ratio = maxWidth / width;
                                    width = maxWidth;
                                    height = height * ratio;
                                }

                                // Create canvas element
                                const canvas = document.createElement("canvas");
                                const ctx = canvas.getContext("2d");

                                // Set canvas dimensions
                                canvas.width = width;
                                canvas.height = height;

                                // Draw image on canvas
                                ctx.drawImage(img, 0, 0, width, height);

                                // Convert canvas to base64
                                const base64data =
                                    canvas.toDataURL("image/jpeg");

                                // Set base64 data to input field
                                $('[name="item_meta[650]"]').val(base64data);
                            };
                            $(".frm_button_submit").prop("disabled", false);
                        })
                        .catch((error) =>
                            console.error("Error fetching image:", error)
                        );
                },
                error: function (error) {
                    console.error("Error fetching image:", error);
                },
            });
        }
    }
}
