import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class Swipers {
    constructor(options = null) {}

    init() {
        this.__swiper1();
        this.__heroBannerSwiper();
        this.__heroBannerImageSwiperManual();
        this.__testimonialCarousel();
        this.__caseStudyCarousel();
        this.__eventGallerySwiper();
        this.__imageGallerySwiper();
    }

    __swiper1() {
        const $swiperBlocks = $(".swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                spaceBetween: 30,
                preventClicks: true,
                breakpoints: {
                    1500: {
                        slidesPerView: 5,
                    },
                    1150: {
                        slidesPerView: 4,
                    },
                    900: {
                        slidesPerView: 3,
                    },
                    630: {
                        slidesPerView: 2,
                    },
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }
    __heroBannerSwiper() {
        const $swiperBlocks = $(".hero_banner_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: true,
                loop: true,
                preventClicks: true,
                allowTouchMove: false,
                effect: "fade",
                fadeEffect: { crossFade: true },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
                // pagination: {
                //     el: ".swiper-pagination-" + swiperBlockId,
                //     type: "bullets",
                //     clickable: true,
                // },
            });
        }
    }
    __heroBannerImageSwiperManual() {
        const $swiperBlocks = $(".manual_hero_banner_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                preventClicks: true,
                allowTouchMove: true,
                effect: "fade",
                fadeEffect: { crossFade: true },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
                pagination: {
                    el: ".swiper-pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: true,
                },
            });
        }
    }

    __testimonialCarousel() {
        const $swiperBlocks = $(".testimonial_carousel_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                preventClicks: true,
                allowTouchMove: true,
                effect: "fade",
                fadeEffect: { crossFade: true },
                breakpoints: {
                    1200: {
                        autoHeight: false,
                    },
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
                pagination: {
                    el: ".swiper-pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: true,
                },
            });
        }
    }
    __caseStudyCarousel() {
        const $swiperBlocks = $(".case_study_carousel_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                preventClicks: true,
                allowTouchMove: true,
                effect: "fade",
                fadeEffect: { crossFade: true },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
                pagination: {
                    el: ".swiper-pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: true,
                },
            });
        }
    }

    __eventGallerySwiper() {
        const $swiperBlocks = $(".event_gallery_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                preventClicks: true,
                breakpoints: {
                    1200: {
                        slidesPerView: 3,
                    },
                    991: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    576: {
                        slidesPerView: 1,
                        centeredSlides: true,
                    },
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }
    __imageGallerySwiper() {
        const $swiperBlocks = $(".image_gallery_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const swiper = new Swiper("#" + swiperBlockId, {
                slidesPerView: 1,
                speed: 750,
                autoplay: false,
                loop: true,
                preventClicks: true,
                breakpoints: {
                    1200: {
                        slidesPerView: 4,
                    },
                    991: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    576: {
                        slidesPerView: 1,
                    },
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }
}
