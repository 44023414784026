import AjaxPost from "./ajax";
import Swipers from "./swiper";
import Base64 from "./image_encoding";
import CountUp from "./count-up";
import NavBarScroll from "./navbar-scroll";
import GoogleMaps from "./google-maps";
import AddAccordionClasses from "./add-accordion-classes";
import Menu from "./menu";
import ToggleNavSearch from "./toggle-nav-search";
import ManageConsent from "./manage-consent";
import AnimalsFilter from "./animals-filter";

jQuery(($) => {
    const swipers = new Swipers({});
    swipers.init();

    const ajaxPost = new AjaxPost({});
    ajaxPost.init();

    const countUp = new CountUp({});
    countUp.init();

    const navBarScroll = new NavBarScroll({});
    navBarScroll.init();

    const googleMaps = new GoogleMaps({});
    googleMaps.init();

    const addAccordionClasses = new AddAccordionClasses({});
    addAccordionClasses.init();

    const menu = new Menu({});
    menu.init();

    if ($('input[name="item_meta[647]"]').length) {
        const hidden = new Base64({});
        hidden.init();
    }

    const toggleNavSearch = new ToggleNavSearch({});
    toggleNavSearch.init();

    const manageConsent = new ManageConsent({});
    manageConsent.init();

    const animalsFilter = new AnimalsFilter({});
    animalsFilter.init();

    $(document).ready(function () {
        if (
            $("#blocks > section:last-of-type").find(".bottom-row").length === 0
        ) {
            $("#blocks > section:last-of-type > section").css(
                "padding-bottom",
                "100px"
            );
        }
    });
    const globalTabs = document.getElementById("global-tabs");
    function checkGlobalTabs() {
        if (globalTabs) {
            const elementsAfterGlobalTabs = Array.from(
                document.querySelectorAll("body *")
            );
            const globalTabsIndex = elementsAfterGlobalTabs.findIndex(
                (element) => element.id === "global-tabs"
            );

            if (globalTabsIndex !== -1) {
                const elementsAfterGlobalTabsWithoutGlobalTabs =
                    elementsAfterGlobalTabs.slice(globalTabsIndex + 1);
                const hasBlockElementAfter =
                    elementsAfterGlobalTabsWithoutGlobalTabs.some((element) =>
                        element.classList.contains("block")
                    );

                if (!hasBlockElementAfter) {
                    globalTabs.style.marginBottom = "80px";
                }
            }
        }
    }

    // Call the function initially and add a resize event listener
    checkGlobalTabs();
    window.addEventListener("resize", checkGlobalTabs);
});
