const $ = jQuery;
export default class AddAccordionClasses {
    constructor(options = null) {}

    init() {
        this.__addAccordionClasses();
    }

    __addAccordionClasses() {
        // Function to add or remove classes based on screen size
        function handleScreenSize() {
            // Get the current window width
            let windowWidth = window.innerWidth;

            // Get the element you want to add/remove classes from
            let $accordionItemElement = $(
                ".staff_members_inner_content_container"
            );
            let $accordionElement = $(".staff_members_content_rows");

            let $accordionCollapseElement = $(
                ".staff_members_inner_content_rows"
            );

            let $trusteeAccordionItemElement = $(
                ".trustees_inner_content_rows"
            );
            let $trusteeAccordionElement = $(".trustees_content_rows");
            let $trusteeAccordionCollapseElement = $(".second-trustees-col");
            let $trusteeCollapseController = $(".first-trustees-col");

            // Check the screen size and add/remove classes accordingly
            if (windowWidth <= 991) {
                // Adjust the screen size threshold as needed
                if ($accordionItemElement) {
                    $accordionItemElement.addClass("accordion-item");
                }
                if ($accordionElement) {
                    $accordionElement.addClass("accordion");
                }
                if ($accordionCollapseElement) {
                    $accordionCollapseElement.addClass("accordion-collapse");
                    $accordionCollapseElement.addClass("collapse");
                }
                if ($trusteeAccordionItemElement) {
                    $trusteeAccordionItemElement.addClass("accordion-item");
                }
                if ($trusteeAccordionElement) {
                    $trusteeAccordionElement.addClass("accordion");
                }

                if ($trusteeAccordionCollapseElement) {
                    $trusteeAccordionCollapseElement.addClass(
                        "accordion-collapse"
                    );
                    $trusteeAccordionCollapseElement.addClass("collapse");
                }
                if ($trusteeCollapseController) {
                    $trusteeCollapseController.attr(
                        "data-bs-toggle",
                        "collapse"
                    );
                }
            } else {
                if ($accordionItemElement) {
                    $accordionItemElement.removeClass("accordion-item");
                }
                if ($accordionElement) {
                    $accordionElement.removeClass("accordion");
                }
                if ($accordionCollapseElement) {
                    $accordionCollapseElement.removeClass("accordion-collapse");
                    $accordionCollapseElement.removeClass("collapse");
                }
                if ($trusteeAccordionItemElement) {
                    $trusteeAccordionItemElement.removeClass("accordion-item");
                }
                if ($trusteeAccordionElement) {
                    $trusteeAccordionElement.removeClass("accordion");
                }

                if ($trusteeAccordionCollapseElement) {
                    $trusteeAccordionCollapseElement.removeClass(
                        "accordion-collapse"
                    );
                    $trusteeAccordionCollapseElement.removeClass("collapse");
                }
                if ($trusteeCollapseController) {
                    $trusteeCollapseController.removeAttr("data-bs-toggle");
                }
            }
        }

        // Initial call to set classes on page load
        handleScreenSize();

        // Attach the function to the window resize event
        window.addEventListener("resize", handleScreenSize);
    }
}
