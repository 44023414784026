const $ = jQuery;
export default class AnimalsFilter {
    constructor(options = null) {}

    init() {
        this.__filter();
    }

    __filter() {
        $(document).ready(function () {
            if ($(".filter-list-container").length) {
                var $close_icon = $(".filter-button-close-icon");
                var $open_icon = $(".filter-button-open-icon");
                $(".filter-button").click(function () {
                    $(".filter-list-container").slideToggle();
                    if ($close_icon.is(":visible")) {
                        $close_icon.hide();
                        $open_icon.show();
                    } else {
                        $close_icon.show();
                        $open_icon.hide();
                    }
                    $(".filter-button-text").text(function (i, text) {
                        return text === "Sort & Filter"
                            ? "Close"
                            : "Sort & Filter";
                    });
                });
            }
        });
    }
}
