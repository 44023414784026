const $ = jQuery;

export default class ToggleNavSearch {
    constructor(options = null) {}

    init() {
        this.__toggleNavSearch();
    }
    __toggleNavSearch() {
        $(document).ready(function () {
            let navBarIcon = document.getElementById("desktop_nav_search_icon");
            let navBarSearch = document.getElementById(
                "desktop_nav_search_field"
            );

            navBarIcon.addEventListener("click", function () {
                navBarSearch.classList.toggle("expand_desktop_search");
            });
        });
    }
}
